import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ServiceItemBanner from '../components/ServiceItemBanner'

import neihu1 from '../assets/images/neihu1.jpg'
import banciao1 from '../assets/images/IMG_7883.jpg'
import yonghe from '../assets/images/trig_003.webp'

const Landing = props => (
  <Layout>
    <Helmet>
      <title>永和、板橋、內湖場館介紹｜三健客3musclers健身俱樂部</title>
      <meta
        name="description"
        content="三健客3musclers在台北內湖、板橋、永和區都有健身房分館，各場館都有多元的健身課程，以及教練之訓練課程，不論您是想挑戰體態雕塑、TRX懸吊訓練、肌力體能訓練等，三健客都有完善的課程等著您來挑戰，一步步陪您達成理想的體態。"
      />
      <meta
        name="keywords"
        content="三健客,健身房,內湖健身房,板橋健身房,永和健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程"
      />
    </Helmet>

    <ServiceItemBanner />

    <div id="main">
      {/* <section id="one" style={{backgroundColor:'#d2aa0d'}}>
        <div className="inner">
          <header className="major">
            <h2>場館介紹</h2>
          </header>
          <p>
            目前三健客擁有三間分店，分別為內湖店、板橋店、永和店
            <br />
          </p>
        </div>
      </section> */}
      <section id="two" className="spotlights">
        <section style={{}}>
          <Link to="/yonghe" className="image">
            <img src={yonghe} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>永和店</h3>
              </header>
              {/* <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p> */}
              <ul className="actions">
                <li>
                  <Link to="/yonghe" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section style={{}}>
          <Link to="/banCiao" className="image">
            <img
              src={banciao1}
              alt="3musclers"
              style={{ objectFit: 'cover' }}
            />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>板橋店</h3>
              </header>
              {/* <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p> */}
              <ul className="actions">
                <li>
                  <Link to="/banCiao" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section style={{}}>
          <Link to="/neihu" className="image">
            <img src={neihu1} alt="3musclers" style={{ height: '100%' }} />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>內湖店</h3>
              </header>
              {/* <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p> */}
              <ul className="actions">
                <li>
                  <Link to="/neihu" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
