import React from 'react'

const ServiceItemBanner = (props) => (
    <section id="banner" className="style2" style={{height:'600px'}}>
        <div className="inner">
            <header className="major">
                <h1>場館介紹</h1>
            </header>
            <div className="content">
                <p>三健客健身房，有多元的健身以及體能訓練課程，場館內也有專業品牌ROGUE、ELEIKO、CYBEX的健身品牌設備，推薦不論是重訓、體態雕塑、肌力體能訓練等運動需求，三健客都可以給您完善的健身計劃及課程，帶領您一起達成自己的理想體態。
                </p>
            </div>
        </div>
    </section>
)

export default ServiceItemBanner
